// Modules exported here will be included in bundle.js
export function showClipboardMessage(button, copyData) {
  const clipboardData = {
    'text/html': new Blob([copyData], {type: 'text/html'}),
  };
  clipboardData['text/plain'] = new Blob([convertToPlainText(copyData)], {type: 'text/plain'});
  const clipboardItem = new ClipboardItem(clipboardData);
  navigator.clipboard.write([clipboardItem]);

  var optionText = button.getElementsByClassName("copyMenu-copy-option")[0];
  optionText.classList.add('not-visible');
  optionText.classList.remove('made-visible');
  var copiedText = button.getElementsByClassName("copyMenu-copied-message")[0];
  copiedText.classList.add('made-visible');
  setTimeout(function() { 
    optionText.classList.add('made-visible');
    optionText.classList.remove('not-visible');
    copiedText.classList.remove('made-visible');
    }, 2000);
};

function convertToPlainText(html){
    let doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || ""; }

export function showCopiedMessage(button, eventType, card) {
  card["eventType"] = eventType;
  card["isDirectAnswer"] = false;
  parent.postMessage(card, "*");

  var optionText = button.getElementsByClassName("copyMenu-copy-option")[0];
  optionText.classList.add('not-visible');
  optionText.classList.remove('made-visible');
  var copiedText = button.getElementsByClassName("copyMenu-copied-message")[0];
  copiedText.classList.add('made-visible');
  setTimeout(function() { 
    optionText.classList.add('made-visible');
    optionText.classList.remove('not-visible');
    copiedText.classList.remove('made-visible');
    }, 2000);
}

export function initPostMessageListeners() {
  // Default to Customer on Case Management
  document.body.classList.add('user-is-customer');

  window.addEventListener('message', (event) => {
    if (event && event.data && typeof event.data === 'object' && event.data.message != "" && 'isCustomer' in event.data) {
      if (event.data.isCustomer) {
        document.body.classList.add('user-is-customer');   
      } else {
        document.body.classList.remove('user-is-customer');
      }
    } else if (event && event.data && typeof event.data === 'object' && event.data.message != "" && 'isInternal' in event.data) {
      if (event.data.isInternal) {
        document.body.classList.add('user-is-internal');   
      } else {
        document.body.classList.remove('user-is-internal');
      }
    }
  }, false);
}
